<template>
  <div id="home">
    <main-header page="accueil" />
    <thematique />

    <program-list-live
      ref="programListLive"
      :programs="programs"
      :empty="empty"
      :selected-filters="selectedFilters"
      :show-all="showAll"
      class="mosaic padding"
      @query-channel="goTo"
      @show-items="showItems" />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import _isEmpty from 'lodash/isEmpty'
import { mapGetters } from 'vuex'
import ProgramListLive from './programListLive/ProgramListLive'
import MainHeader from '../shared/mainHeader/MainHeader'
import Thematique from '../shared/thematique/Thematique'
import { CONFIG_META } from '../../../config.model'
import getFormatedDateFilter from '../../../plugins/filters/getFormatedDate/getFormatedDate.filter'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'

export default {
  name: 'HomePage',
  components: {
    MainHeader,
    Thematique,
    ProgramListLive
  },
  layout: 'main',
  asyncData ({ store, $config }) {
    const requests = []

    if (_isEmpty(store.getters['programs/getCurrentPrograms'])) {
      requests.push(store.dispatch('programs/getFirstCurrentPrograms'))
    }

    return Promise.allSettled(requests)
  },
  data () {
    return {
      refreshInterval: null,
      fetchInterval: null,
      description: (`TV en direct, ce soir ${getFormatedDateFilter(DateTime.local())}, programme TV, streaming. Votre offre internet Orange inclut jusque 70 chaînes télé gratuites parmi plus de 250 chaînes TNT, locales, et étrangères.`).slice(0, CONFIG_META.NB_MAX_CHAR_META_DESC)
    }
  },
  head () {
    return {
      title: 'Programme TV, ce soir, télé en direct par Orange.',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.description
        }
      ]
    }
  },
  meta: {
    oData: { page: 'home' }
  },
  computed: {
    ...mapGetters({
      channels: 'channels/getChannelsSTB',
      showAll: 'config/getShowAllHome',
      programs: 'programs/getCurrentPrograms',
      formatedGenres: 'genres/getFormatedGenres',
      selectedFilters: 'genres/getSelectedFilters',
      channelIdToFocus: 'channels/getChannelIdWillFocus'
    }),
    empty () {
      return this.selectedFilters.length > 0
        ? this.$t('general.noContentThematique')
        : this.$t('general.noContent')
    },
    showMosaic () {
      return this.articles?.length > 0 && this.checkWorkingImages()
    }
  },
  mounted () {
    // used to set the focus of the first element of the memorised id
    if (this.channelIdToFocus) {
      setTimeout(() => {
        setFocusOnFirstFocusableChild(document.getElementById(this.channelIdToFocus))
        this.$store.commit('channels/resetChannelIdWillFocus')
      }, 0)
    }
    if (this.$route.query.filtres) {
      // supposed to recieve a string of genres seperated by a coma, not at the same format of the genres in the store
      const filters = this.$route.query.filtres.split(',').map(e => this.formatedGenres[e]).filter(e => e !== undefined)
      this.$store.commit('genres/setSelectedFilters', filters)
    }
    // on rafraichit cette liste toutes les minutes
    // car il se peut qu'il y ait des chaines sans info à l'instant t et qu'elles aient du contenu la minute d'après
    // pas d'autre moyen que de rafraichir toutes les 30 secondes
    this.refreshInterval = setInterval(this.refreshPrograms, 30000)

    // on fait également un appel api toutes les 30 minutes pour renouveler la tranche de programmes
    this.fetchInterval = setInterval(this.fetchPrograms, 30 * 60 * 1000)
  },
  beforeDestroy () {
    clearInterval(this.refreshInterval)
    clearInterval(this.fetchInterval)
  },
  methods: {
    showItems () {
      this.$store.commit('config/setShowAllHome', true)
    },
    fetchPrograms () {
      this.$store.dispatch('programs/getCurrentProgramsByChannels')
    },
    refreshPrograms () {
      this.$store.commit('programs/setCurrentPrograms', {
        programs: this.$store.getters['programs/getCurrentProgramsByChannels'],
        userTimeOffset: this.$store.getters['user/getTimeOffset'],
        notSubscribed: this.$store.getters['npvr/notSubscribed'],
        channels: this.channels
      })
    },
    goTo (channelId) {
      const programChannel = document.getElementById(channelId)

      if (!programChannel) {
        this.$refs.programListLive.expand()
      }

      setTimeout(() => {
        document.getElementById(channelId).scrollIntoView()
        this.$root.$emit('setPlayerChannel', { idEPG: this.$route.query.chaine || channelId })
      }, 1000)
    },
    checkWorkingImages () {
      let images = []
      this.articles.forEach((article) => {
        images = images.concat(article.images)
      })

      return images.some(image => image.type === 'header')
    }
  }
}
</script>

<style lang="scss" scoped>
  .mosaic {
    @include mediaQueries('padding', (xsmall: 0 15px 50px, medium: 0 12px 45px, large: 0 32px 45px, xlarge: 0 40px 45px));
  }
</style>
