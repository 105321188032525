<template>
  <div class="program-list-wrapper">
    <h2 class="sr-only">
      {{ $t('home.accessibility.title') }}
    </h2>
    <div
      v-if="programs.length > 0"
      class="program-list">
      <ul
        ref="wptvGrid"
        class="wptv-grid">
        <li
          v-for="program in visiblePrograms"
          :id="program.channelId"
          :key="program.id"
          :ref="'prgmListItem'"
          :class="['list-item-wrapper wptv-xsmall-1 wptv-medium-3 wptv-large-4']"
          @click="onClick(program.channelId)"
          @keyup.enter="onClick(program.channelId)">
          <list-item-live
            :current-time="currentTime"
            :program="program"
            :track-zone="trackZone" />
        </li>
      </ul>
      <div v-if="programs.length > 12" class="show-more-list">
        <wptv-button
          v-if="!showAll"
          v-tealium:click="$stats.global.onClickShowMore(trackPage, trackZone)"
          :aria-label="$t('grid.accessibility.showAll')"
          :title="$t('grid.accessibility.showAll')"
          type="wired"
          data-wptv="live-grid-voir_plus"
          @click.native="expand()">
          {{ $t('grid.showAll') }}
        </wptv-button>
      </div>
    </div>
    <div
      v-else
      class="empty-list">
      {{ empty }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { CONFIG_MOSAIC } from './../../../../config.model'
import { WptvButton } from '@wptv/components/ui/button'
import ListItemLive from './listItemLive/ListItemLive'
import { setFocusOnFirstFocusableChild } from '@wptv/components/utils'

export default {
  name: 'ProgramListLive',
  components: {
    WptvButton,
    ListItemLive
  },
  props: {
    empty: {
      type: String,
      required: true
    },
    programs: {
      type: Array,
      required: true
    },
    showAll: {
      type: Boolean,
      required: true
    },
    selectedFilters: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      trackZone: 'mosaique',
      currentTime: Date.now(),
      pageLoaded: false
    }
  },
  computed: {
    ...mapGetters({
      trackPage: 'meta/getPage',
      timeOffset: 'user/getTimeOffset',
      getChannelByEditoId: 'channels/getChannelByEditoId'
    }),
    filteredPrograms () {
      return this.selectedFilters.length > 0 && this.selectedFilters[0] !== 'all'
        ? this.programs.filter(program => this.selectedFilters.includes(program.genre))
        : this.programs
    },
    visiblePrograms () {
      return this.showAll ? this.filteredPrograms : this.filteredPrograms.slice(0, CONFIG_MOSAIC.MOSAIC_NB_VISIBLE)
    }
  },
  mounted () {
    // this is for firefox to be able to launch player automaticaly when arriving on page with 'chaine' param
    // US 6996 - [Firefox] le lien direct vers le live ne marche pas toujours
    if (document.readyState === 'complete' && !this.pageLoaded && (this.$route.query.chaine || this.$route.query.zap)) {
      let zap
      if (this.$route.query.zap) {
        zap = this.getChannelByEditoId(this.$route.query.zap)?.idEPG
      }
      const channel = this.$route.query.chaine || zap
      this.$emit('query-channel', channel)
      this.pageLoaded = true
    } else {
      document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete' && !this.pageLoaded && (this.$route.query.chaine || this.$route.query.zap)) {
          let zap
          if (this.$route.query.zap) {
            zap = this.getChannelByEditoId(this.$route.query.zap)?.idEPG
          }
          const channel = this.$route.query.chaine || zap
          this.$emit('query-channel', channel)
          this.pageLoaded = true
        }
      })
    }

    this.intervalCurrentTime = setInterval(() => {
      this.currentTime = Date.now() - this.timeOffset
    }, 10000)
  },
  beforeDestroy () {
    clearInterval(this.intervalCurrentTime)
  },
  methods: {
    onClick (id) {
      this.$store.commit('user/setIdToFocus', id)
    },
    expand () {
      this.$emit('show-items')
      setTimeout(() => {
        setFocusOnFirstFocusableChild(this.$refs.prgmListItem[12])
      }, 0)
    }
  }
}
</script>

<style scoped lang="scss">
.program-list-wrapper {
  .wptv-grid {
    margin-top: 10px;
    @include mediaQueries(margin-top, (medium: 15px));
  }
}
</style>
