<template>
  <div :class="['list-item', { 'brightness': program.channel.notSubscribed }]">
    <div
      data-wptv="live-item-cover"
      class="image-wrapper">
      <wptv-poster
        :ratio="POSTER_CONFIG.RATIO.RATIO_16_9"
        :show-subscribe="program.channel.notSubscribed"
        :show-play="true"
        :csa="program.csa"
        :image-data="program.imageData"
        :logo="program.channel.logo"
        :show-zapping-number="true"
        :zapping-number-visibility="POSTER_CONFIG.ZAPPING_NUMBER_VISIBILITY.MOBILE"
        :zapping-number="program.channel.zappingNumber"
        :alt-image="program.altCover"
        :alt-logo="program.channel.name"
        :alt-play="program.title"
        :alternative-text="getAltText"
        :progress-value="timeElapsed"
        :disabled="program.channel.notSubscribed"
        :is-aria-hidden="true"
        :recording-in-progress="!!programRecording"
        tabindex="0"
        role="heading"
        aria-level="3"
        class="image-link"
        @keyup.enter.native="onClickCover()"
        @click.native="onClickCover()" />
    </div>
    <div
      data-wptv="live-item-infos"
      class="infos-wrapper">
      <item-informations
        :program="program"
        :title="program.title"
        :remaining-time="remainingTime"
        :alt-text="programInfosAlternativeText"
        :stats="$stats.home.onClickChannelTitleHome(program.channel, hasAccess, $config)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { DateTime, Interval } from 'luxon'
import { WptvPoster, WptvPosterConfig } from '@wptv/components/ui/poster'
import zap from '../../../shared/zap/zap.mixin'
import ItemInformations from '../../../shared/itemInformations/ItemInformations'
import { profileHasAccess } from '../../../../../middleware/checkups'
import { ProgramFormat, ProgramFieldPresets } from '../../../../shared/programFormat/programFormat'

export default {
  name: 'ListItemLive',
  components: {
    ItemInformations,
    WptvPoster
  },
  mixins: [zap],
  props: {
    program: {
      type: Object,
      required: true
    },
    trackZone: {
      type: String,
      required: true
    },
    currentTime: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      authUrl: this.$config.confetti.public.authUrl,
      POSTER_CONFIG: WptvPosterConfig
    }
  },
  computed: {
    ...mapGetters({
      profile: 'core/getProfile',
      getProgramRecording: 'npvr/getProgramRecording'
    }),
    hasAccess () {
      return profileHasAccess(this.profile)
    },
    programRecording () {
      return this.getProgramRecording(this.program, this.program?.channel)
    },
    timeElapsed () {
      const timeElapsed = ((this.currentTime - (this.program.diffusionDate * 1000)) / (this.program.duration * 1000)) * 100
      if ((this.currentTime < this.program.diffusionDate * 1000) || this.program.diffusionDate === 0 || this.program.duration === 0 || timeElapsed > 100) {
        return 0
      }
      return timeElapsed
    },
    remainingTime () {
      const interval = Interval.fromDateTimes(DateTime.fromMillis(this.currentTime), DateTime.fromMillis(this.program.endDiffusionDate)).count('minutes')
      return interval ? this.$t('general.items.remainingTime', { time: interval }) : ''
    },
    getAltText () {
      if (this.program) {
        const programFormat = new ProgramFormat(this.$root, this.program)
        return programFormat.format(ProgramFieldPresets.HomeCover, this.$t('fip.watch'))
      }

      return ''
    },
    programInfosAlternativeText () {
      if (this.program) {
        const programFormat = new ProgramFormat(this.$root, this.program)
        return programFormat.format(ProgramFieldPresets.HomeInfos, this.$t('general.moreInfosAbout'))
      }

      return ''
    }
  },
  methods: {
    onClickCover () {
      this.redirectToBoutiqueFipOrPlay(this.program.channel, this.program)
      this.sendStats(this.$stats.home.onClickCover(this.program.channel, this.hasAccess, this.program.channel.notSubscribed, this.$config))
    }
  }
}
</script>

<style scoped lang="scss">
.image-link {
  @extend %focus-visible;
}
</style>
